<template>
  <div class="page">
    <div style="background-color: #FFFFFF">
      <div style="color: black;font-weight: bold;display: flex; align-items: center;" class="font-size16 main-title">
        创建优惠券
      </div>
      <div class="border-radius4 main-back" style="background: #FFFFFF;display: flex;">
        <el-button type="primary" size="small" @click="goBack">返回</el-button>
      </div>
      <meta content="no-referrer" name="referrer" />
      <div class="content-box section">
        <el-form ref="form" :model="model" :rules="rules" label-width="100px" size="small" label-position="left">

          <easy-card title="基础信息">
            <div class="add-coupon-basic">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="标题" prop="title">
                    <el-col :span="20">
                      <el-input v-model="model.title" placeholder="请输入标题内容"></el-input>
                    </el-col>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="适用业务类型" prop="businessType">
                    <el-col :span="20">

                      <el-select v-model="model.businessType" placeholder="适用业务类型" style="width: 100%">
                        <el-option label="通用券" value="0"></el-option>
                        <el-option label="停车券" value="1"></el-option>
                        <el-option label="洗车券" value="2"></el-option>
                        <el-option label="充电券" value="3"></el-option>
                      </el-select>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-form-item label="参与结算" prop="isSettle">
                  <el-radio-group v-model="model.isSettle">
                    <el-radio label="0">参与</el-radio>
                    <el-radio label="1">不参与</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="承担方" prop="undertakerId">
                    <!-- <Bearer v-model="model.bearer" style="width: 100%" /> -->
                    <el-col :span="20">
                      <el-select v-model="model.undertakerId" placeholder="请选择承担方" @change="changeUnder"
                        style="width: 100%">
                        <el-option v-for="item, index in underList" :key="index" :label="item.userName"
                          :value="item.userId">
                        </el-option>
                      </el-select>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-form-item label="适用范围" prop="scope">
                  <el-radio-group v-model="model.scope" @change="changeScope">
                    <el-radio label="0">车场</el-radio>
                    <el-radio label="1">结算方</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :span="12" v-if="model.scope == 0 && showScope">
                  <el-form-item label="车场" prop="parkingLotIds">
                    <!-- <Bearer v-model="model.bearer" style="width: 100%" /> -->
                    <el-select v-model="model.parkingLotIds" :multiple="true" placeholder="请选择车场" clearable filterable
                      style="width: 100%">
                      <el-option v-for="(item, index) in parkingList" :key="index" :label="item.parkingLotName"
                        :value="item.parkingLotId"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="12" v-if="model.scope == 1">
                  <el-form-item label="结算方" prop="settleId">
                    <!-- <Bearer v-model="model.bearer" style="width: 100%" /> -->
                    <el-select v-model="model.settleId" placeholder="请选择结算方" style="width: 100%">
                      <el-option v-for="item, index in settleList" :key="index" :label="item.userName"
                        :value="item.userId">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="上架时间" prop="startTime">
                    <el-col :span="20">

                      <el-date-picker v-model="model.startTime" :clearable="false" placeholder="请选择上架时间"
                        style="width: 100%" type="datetime" value-format="yyyy-MM-dd HH:mm:ss">
                      </el-date-picker>
                    </el-col>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="下架时间" prop="endTime">
                    <el-col :span="20">
                      <el-date-picker v-model="model.endTime" placeholder="请选择下架时间" style="width: 100%" type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss">
                      </el-date-picker>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="备注" prop="remark">
                    <el-col :span="20">
                      <el-input v-model="model.remark" maxlength="300" placeholder="请输入备注内容(字数限制300字以内)" show-word-limit
                        type="textarea">
                      </el-input>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- </el-form> -->
            </div>
          </easy-card>

          <!-- <div class="separate-line"></div> -->

          <easy-card title="优惠券信息">
            <!-- <el-form ref="form2" :model="model" :rules="rules" label-width="100px" size="small" label-position="left"> -->
            <el-row>
              <el-col :span="12">
                <el-form-item label="优惠券类型" prop="couponType">
                  <el-col :span="20">
                    <el-select v-model="model.couponType" placeholder="请选择优惠券类型" style="width: 100%;">
                      <el-option label="全免" value="0"></el-option>
                      <el-option label="面值" value="1"></el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="优惠券额度" prop="price">
                  <el-col :span="20">
                    <el-input v-model="model.price" placeholder="请输入优惠券额度"></el-input>
                  </el-col>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="单次获券张数" prop="singleAccessRestriction">
                  <el-col :span="20">
                    <el-input v-model="model.singleAccessRestriction" placeholder="请输入获券张数"></el-input>
                  </el-col>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="使用金额限制" prop="amountLimit">
                  <el-col :span="20">
                    <el-input v-model="model.amountLimit" placeholder="请输入使用金额限制"></el-input>
                  </el-col>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="有效期限制" prop="effectiveTimeLimit">
                  <el-radio-group v-model="model.effectiveTimeLimit">
                    <el-radio label="0">
                      <span style="display: inline-block; margin-right: 20px; margin-top:12px">固定天数</span>
                      <div style="display: inline-block;">
                        <el-input v-model="model.effectiveTimeDay" style="margin-top: -10px; margin-left:28px">
                          <template slot="append">天</template>
                        </el-input>
                      </div>
                    </el-radio>

                    <div>
                      <el-radio label="1">
                        <span style="display: inline-block; margin-right: 20px; margin-top:20px;">固定日期</span>
                      </el-radio>
                      <div style="display: inline-block;">
                        <el-date-picker v-model="model.effectiveTimeStartDate" placeholder="开始日期" type="date"
                          value-format="yyyy-MM-dd">
                        </el-date-picker>
                        <el-date-picker v-model="model.endDate" placeholder="结束日期" type="date"
                          value-format="yyyy-MM-dd">
                        </el-date-picker>
                      </div>
                    </div>
                    <div>
                      <el-radio label="2">
                        <span style="display: inline-block; margin-right: 20px; margin-top:16px;">领取当天24点</span>
                      </el-radio>
                    </div>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="领券离场时长" prop="couponFreeTime">
                  <el-col :span="20">
                    <el-input v-model="model.couponFreeTime" placeholder="请输入领券离场时长(分钟)"></el-input>
                  </el-col>
                </el-form-item>
              </el-col>
            </el-row>

            <el-button class="xxd-button_save" type="primary" @click="save">保存
            </el-button>
          </easy-card>
        </el-form>

      </div>
      <SuccessDialog v-model="showDialog" @closeDialog="closeSuccDialog" :title="'操作成功'" :tip="successTip" />
    </div>
  </div>
</template>


<script>
import {
  addSendCoupon
} from "@/api/coupon";
import {
  getSettleList,
  getMerchantList2,
  getParkingLot
} from "@/api/common";
// import SuggestParking from "@/components/SuggestParking";
// import { Message } from "element-ui";
import SuccessDialog from "@/components/SuccessDialog";

export default {
  data() {
    return {
      isLoading: false,
      dataList: [],
      ParkingLotId: '',
      showScope: false,
      model: {
        title: "",
        businessType: "",
        isSettle: "0",
        undertakerId: "",
        scope: "",
        parkingLotIds: "",
        startTime: "",
        endTime: "",
        remark: "",
        price: "",
        couponType: "",
        amountLimit: "",
        singleAccessRestriction: "",
        effectiveTimeLimit: "",
        effectiveTimeDay: "",
        effectiveTimeStartDate: "",
        effectiveTimeEndDate: "",
      },
      rules: {
        title: [{ required: true, message: "标题不能为空" }],
        businessType: [{ required: true, message: "业务类型不能为空" }],
        isSettle: [{ required: true, message: "参与结算不能为空" }],
        undertakerId: [{ required: true, message: "承担方不能为空" }],
        scope: [{ required: true, message: "适用范围不能为空" }],
        parkingLotIds: [{ required: true, message: "请选择" }],
        settleId: [{ required: true, message: "请选择结算方" }],
        startTime: [{ required: true, message: "请选择上架时间" }],
        endTime: [{ required: true, message: "请选择下架时间" }],
        couponType: [{ required: true, message: "请选择优惠券类型" }]
      },
      fuList: [],
      basicInfo: {},
      underList: [],
      settleList: [],
      parkingList: [],
      showDialog: false,
      successTip: ""
    };
  },
  components: {
    // SuggestParking,
    SuccessDialog
  },
  methods: {
    closeSuccDialog(e) {
      console.log(e);
      this.showDialog = false;
    },
    //返回停车订单页面
    goBack() {
      this.$router.back();
    },
    addFuNum() {
      this.fuList.push({
        type: 1,
        num: ""
      })
    },
    changeScope() {
      this.showScope = true;
    },
    delFuNum(index) {
      this.fuList.splice(index, 1)
    },
    changeUnder(e) {
      this.model.parkingLotIds = [];
      getParkingLot({ merchantId: e }).then(res => {
        this.parkingList = res.returnObject;
      })
    },
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          addSendCoupon(this.model).then(res => {
            if (res && res.code == 30) {
              this.showDialog = true;
              // Message.success("新增成功");
              // this.$router.back();
            }
          })
        }
      })
    }
  },
  async mounted() {
    // 承担方
    const res = await getMerchantList2();
    if (res && res.code == 30) {
      this.underList = res.returnObject;
    }

    // 结算方
    const result = await getSettleList();
    if (result && result.code == 30) {
      this.settleList = result.returnObject;
    }
  },
};
</script>

<style lang="less" scoped>
.record-tilte {
  margin: 14px 0;
}

.span-margin {
  margin-left: 4px;
}

.showPicture {
  display: flex;
  flex-direction: row;
}

.pictureBox {
  border: 1px solid #bbb;
  width: 100%;
}

.boxF1 {
  margin-right: 10px;
}

.boxF2 {
  margin-left: 10px;
}

.carNumber {
  width: 100%;
  line-height: 30px;
  text-align: center;
  background: #0768FD;
  color: #fff;
}

.inOrOutTime {
  background: #f0f1f5;
  // padding-left: 15px;
  width: 100%;
  display: inline-block;
  line-height: 30px;
}

.photo-width {
  margin: 12px 8px;

  .photo-item {
    width: 100%;
    //display: flex;

    .top-img {
      //width: 284px;
      //height: 184px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}

.table-list {
  margin-top: 20px;
}

/deep/ .el-button--text {
  color: #0768FD !important;
}

.main-title {
  padding-left: 18px;
  padding-top: 18px;
  padding-bottom: 20px;
  border-bottom: 1px solid #FAFAFA;
}

.main-back {
  padding-left: 18px;
  margin-top: 20px;
  padding-bottom: 2px;
}
</style>